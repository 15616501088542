.tbl{
    width: 100%;
    margin-top: 1rem;
    overflow-x: auto !important;
    box-shadow: 0 3px 8px #0000003d
}

.col{
    text-align: center;
}
