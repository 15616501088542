.container{
    gap: 2rem;
    width: 100%;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 5rem;
    min-height:80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.animation{
    height: 25rem;
    width: 20rem;
    margin-top: -3rem;
}

.ask{
    margin-top: -6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 0.25rem; */
}

.question{
    font-size: 1.25rem;
    font-weight: 600
}


.button {
    z-index: 2;
    position: relative;
    width: 100%;
    border: none;
    background-color: rgb(30 58 138);
    /* height: 30px; */
    padding: 0.5rem;
    color: white;
    font-size: 1.05rem;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .button:hover {
    background-color: rgb(22, 43, 100);
  }